import React from "react";

const BlockMetaData = () => {
  const blockMetaCotent = [
    {
      _id: 1,
      icon: "mail",
      title: "Email Marketing",
      metaHoverContent: ` Send targeted and engaging emails to your subscribers.`,
    },
    {
      _id: 2,
      icon: "drag",
      title: "Drag and drop",
      metaHoverContent: ` Create stunning emails with an intuitive drag and drop interface.`,
    },
    {
      _id: 3,
      icon: "market",
      title: "Marketing Automation",
      metaHoverContent: ` Automate your marketing campaigns and save time.`,
    },
    {
      _id: 4,
      icon: "popup",
      title: "Popup Builder",
      metaHoverContent: ` Build beautiful popups to capture your visitors' attention.`,
    },
  ];
  return (
    <>
      {blockMetaCotent.map((blockMeta) => (
        <div className="block-meta-data text-center" key={blockMeta._id}>
          <div className="line-dot-container">
            <div className="icon-box">
              <img src={`images/icon/${blockMeta.icon}.png`} alt="icon" width="50px"/>
            </div>
            {/* /.icon-box */}
            <p>{blockMeta.title}</p>
          </div>
          <div className="hover-content">
            <span>{blockMeta.metaHoverContent}</span>
          </div>
        </div>
      ))}
    </>
  );
};

export default BlockMetaData;
