/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
//import HeaderTwo from "../../../../components/header/HeaderTwo";
import Social from "../../../../components/social/Social";
import FaqThree from "../../../../components/faq/FaqThree";
import CopyRightTwo from "../../../../components/footer/CopyRightTwo";
import FooterTwo from "../../../../components/footer/FooterTwo";
//import Pricing from "../../../../components/pricing/pricing-one/Pricing";
import CallToActionThree from "../../../../components/call-to-action/CallToActionThree";
import HeaderLanding from "../../../../components/header/landing/HeaderLanding";
import PricingFiveCopy from "../../../../components/pricing/PricingFiveCopy";

const PricingCustomerSupport = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          Pricing EmailHup || Get Best Pricing
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* <HeaderTwo /> */}
      <HeaderLanding />
      {/* End Header */}

      {/* =============================================
				Pricing Section Two
			==============================================  */}
      <div className="pricing-section-two pricing-section-two-custom">
        <div className="fancy-hero-four" style={{ background: '#0E1122' }}>
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <div className="shapes shape-five"></div>
          <div className="shapes shape-six"></div>
          <div className="bg-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-xl-10 col-lg-11 m-auto ">
                  <h2 className="font-recoleta text-white">Choose package that match your budget</h2>
                </div>
                <div className="col-12 m-auto">
                  <p className="font-rubik sub-heading text-white">
                  30-Day Money-Back Guarantee · Try plan features for 14 days ·
                    No credit card required
                  </p>
                </div>
              </div>
              {/* End .row */}

              <div className="col-lg-10">
                <div className="pricing-table-area-five md-mt-60">
                  <img
                    src="images/icon/88.png"
                    alt="icon"
                    className="shapes shape-one"
                  />
                  <img
                    src="images/shape/160.png"
                    alt="shape"
                    className="shapes shape-two"
                  />
                  {/* <PricingFive/> */}
                  <PricingFiveCopy />
                </div>
                {/* /.pricing-table-area-five */}
              </div>
              {/* End .col */}
            </div>
            {/* End .container */}
          </div>
          {/*  /.bg-wrapper */}
        </div>
        {/* /.fancy-hero-four */}



        {/* <div className="container">
          <div className="pricing-table-area">
            <div className="tab-content">
              <Pricing />
              
            </div>
            <img
              src="https://3dicons.sgp1.cdn.digitaloceanspaces.com/v1/dynamic/premium/at-dynamic-premium.png"
              alt="shape"
              className="shapes shape-one"
              width="140px"
            />
            <img
              src="https://3dicons.sgp1.cdn.digitaloceanspaces.com/v1/dynamic/premium/at-dynamic-premium.png"
              alt="shape"
              className="shapes shape-two"
              width="120px"
            />
          </div> 
        </div> */}
      </div>
      {/* /.pricing-section-two  */}

      {/* 	=============================================
				Fancy Text block Seven
			==============================================  */}
      <div className="fancy-text-block-seven mt-130 md-mt-80">
        <div className="bg-wrapper no-bg">
          <img
            src="images/shape/29.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/30.svg"
            alt="shape"
            className="shapes shape-two"
          />
          <img
            src="images/shape/31.svg"
            alt="shape"
            className="shapes shape-three"
          />
          <img
            src="images/shape/32.svg"
            alt="shape"
            className="shapes shape-four"
          />
          <img
            src="images/shape/33.svg"
            alt="shape"
            className="shapes shape-five"
          />
          <div className="container">
            <div className="row">
              <div
                className="col-lg-5 col-md-6 col-sm-10 m-auto"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="img-holder">
                  <img src="images/media/a.png" alt="media" />
                  <img
                    // src="images/shape/122.webp"
                    src="https://3dicons.sgp1.cdn.digitaloceanspaces.com/v1/dynamic/premium/at-dynamic-premium.png"
                    alt="shape"
                    className="shapes shape-six"
                    width="60px"
                  />
                </div>
              </div>
              <div
                className="col-xl-6 col-lg-7 ms-auto"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="quote-wrapper pt-60">
                  <img src="images/icon/26.svg" alt="icon" className="icon" />
                  <blockquote className="font-rubik">
                    "EmailHup was founded with the vision of empowering businesses with an intuitive, reliable, and feature-rich platform for simplifying email marketing and achieving their goals.
                  </blockquote>
                  <h6 className="mt-20">
                    Abel O. <span>CEO & Founder Oanwave</span>
                  </h6>
                </div>
               
              </div>
            </div>
          </div>
        </div>
        {/*  /.bg-wrapper */}
      </div>
      {/* /.fancy-text-block-seven */}

      {/*=====================================================
				Useable Tools
			===================================================== */}
      <div className="useable-tools-section-two bg-shape mb-200 mt-150 md-mt-50 md-mb-120">
        <div className="bg-wrapper">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <div className="container">
            <div className="title-style-six text-center mb-70 md-mb-10">
              <div className="row">
                <div
                  className="col-lg-10 col-md-11 m-auto"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <p>Integrates through SMTP credentials and API.</p>
                  <h2>
                    Improve your business efficiency with EmailHup's {" "}
                    
                      seamless native {" "}
                      <span style={{color:"#714FE4"}}>
                      integration
                      {/* <img src="images/shape/line-shape-2.svg" alt="" /> */}
                    </span>
                    .
                  </h2>
                  <a href="https://app.emailhup.com/register" className="theme-btn-fourteen mt-60 md-mt-40">
                    Try out now
                  </a>
                </div>
              </div>
            </div>
            {/*  /.title-style-two */}

            <div className="icon-wrapper">
              <Social />
            </div>
            {/* /.icon-wrapper */}
          </div>
          {/* /.container */}
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.useable-tools-section-two */}

      {/* 	=====================================================
				FAQ Section
			===================================================== */}
      <div className="faq-section">
        <div className="container">
          <div className="title-style-six text-center mb-100 md-mb-80">
            <p>FAQ</p>
            <h2>Question & Answer</h2>
          </div>
          {/* /.title-style-one */}

          <div className="row">
            <div className="col-xl-9 col-lg-10 m-auto">
              <FaqThree />
            </div>
          </div>
          {/* /.row */}

          <div
            className="text-center mt-75 md-mt-50"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <h3 className="mb-35 font-recoleta">Don’t find your answer?</h3>
            <Link className="theme-btn-fourteen" to="/faq">Contact us</Link>
            
          </div>
        </div>
      </div>
      {/* End .faq-section */}

      {/* 	=====================================================
			Fancy Short Banner Three
			===================================================== */}
      <div className="fancy-short-banner-three mt-200 md-mt-150">
        <div className="container">
          <div className="bg-wrapper">
            <CallToActionThree />
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-short-banner-four */}

      {/* 	=====================================================
				Footer Style Two
			===================================================== */}
      <footer className="theme-footer-two pt-150 md-pt-80">
        <div className="top-footer">
          <div className="container">
            <FooterTwo />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightTwo />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default PricingCustomerSupport;
