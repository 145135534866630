import React from 'react'

const BlockMetaDataTwo = () => {
    const blockMetaCotent = [
        {
          _id: 1,
          icon: "lead",
          title: "Lead Form",
          metaHoverContent: ` Create lead forms to grow your email list and generate leads.`,
        },
        {
          _id: 2,
          icon: "list",
          title: "Email List Management",
          metaHoverContent: `  Manage and segment your email list for better targeting.`,
        },
        {
          _id: 3,
          icon: "temp",
          title: "Email Template Library",
          metaHoverContent: ` Access a wide range of customizable email templates for every occasion.`,
        },
        {
          _id: 4,
          icon: "lib",
          title: "Templates Library",
          metaHoverContent: ` Get access to a wide range of templates for landing pages, forms, and more.`,
        },
      ];
  return (
    <>
    {blockMetaCotent.map((blockMeta) => (
      <div className="block-meta-data text-center" key={blockMeta._id}>
        <div className="line-dot-container">
          <div className="icon-box">
            <img src={`images/icon/${blockMeta.icon}.png`} alt="icon" width="50px"/>
          </div>
          {/* /.icon-box */}
          <p>{blockMeta.title}</p>
        </div>
        <div className="hover-content">
          <span>{blockMeta.metaHoverContent}</span>
        </div>
      </div>
    ))}
  </>
  )
}

export default BlockMetaDataTwo