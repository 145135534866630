import React from "react";
import { Link } from "react-router-dom";

const FancyFeatureEleven = () => {
  return (
    <>
      <div className="block-style-sixteen" style={{ background: "#EBF3F1" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#DEEAE7" }}
              >
                <img src="images/icon/73.svg" alt="icon" />
              </div>
              <h3 className="title font-recoleta">Best Price Advantage.</h3>
              <p>
              EmailHup offers a competitive edge with its one-time payment feature, allowing you to enjoy the benefits of a comprehensive email marketing platform at the best price in the market.
              </p>
              <a href="https://app.emailhup.com/">
                <img src="images/icon/72.svg" alt="icon" />
              </a>
            </div>
          </div>
        </div>
        <img
          src="images/assets/price.png"
          alt=""
          className="shapes screen-one"
        />
      </div>
      {/* /.block-style-sixteen */}

      <div className="block-style-sixteen" style={{ background: "#EDF3F9" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6 ms-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#E1E7ED" }}
              >
                <img src="images/icon/74.svg" alt="icon" />
              </div>
              <h3 className="title font-recoleta">Tailored Embedded Forms.</h3>
              <p>
              EmailHup provides tailored embedded forms that can be customized to match the branding of your website, ensuring a seamless user experience for your subscribers.
              </p>
              <a href="https://app.emailhup.com/">
                <img src="images/icon/72.svg" alt="icon" />
              </a>
            </div>
          </div>
        </div>
        <img
          src="images/assets/feat02.png"
          alt="screen"
          className="shapes screen-two"
        />
      </div>
      {/* /.block-style-sixteen */}

      <div className="block-style-sixteen" style={{ background: "#F9F1EF" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#EDE5E3" }}
              >
                <img src="images/icon/75.svg" alt="icon" />
              </div>
              <h3 className="title font-recoleta">Click & Delivery Tracking.</h3>
              <p>
              With EmailHup, every email's delivery status is automatically tracked and recorded, including opens, clicks, bounces, and complaints.
              </p>
              <a href="https://app.emailhup.com/">
                <img src="images/icon/72.svg" alt="icon" />
              </a>
            </div>
          </div>
        </div>
        <img
          src="images/assets/feat003.png"
          alt="screen"
          className="shapes screen-three"
        />
      </div>
      {/*  /.block-style-sixteen */}
    </>
  );
};

export default FancyFeatureEleven;
