import React, { useState } from "react";
import Slider from "react-slick";

export default function SlickAsNav() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  var settings = {
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider
        className="slick-nav-none"
        asNavFor={nav2}
        ref={(slider1) => setNav1(slider1)}
        autoplay={true}
      >
        <div className="item">
          <img src="images/assets/feat01.png" alt="feature" />
        </div>
        <div className="item">
          <img src="images/assets/dash01.png" alt="feature" />
        </div>
        <div className="item">
          <img src="images/assets/dash03.png" alt="feature" />
        </div>
      </Slider>
      {/* Top Image slide */}

      <Slider
        {...settings}
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
        swipeToSlide={true}
        focusOnSelect={true}
        className="slick-nav-custom"
      >
        <div className="block-style-eleven mt-40">
          <div className="num font-rubik">01</div>
          <div className="title">Email Automations</div>
          <p className="font-rubik">
          Automate your email marketing by setting up a series of emails workflows.
          </p>
        </div>
        {/* /.block-style-eleven */}
        <div className="block-style-eleven mt-40">
          <div className="num font-rubik">02</div>
          <div className="title">Advanced Analytics</div>
          <p className="font-rubik">
          Maximize your email campaigns with detailed analytics and reporting.
          </p>
        </div>
        {/* /.block-style-eleven */}
        <div className="block-style-eleven mt-40">
          <div className="num font-rubik">03</div>
          <div className="title">Email Builder</div>
          <p className="font-rubik">
          Easily create customizable email templates or design from our templates.
          </p>
        </div>
        {/* /.block-style-eleven */}
      </Slider>
      {/* Bottom text slide */}
    </>
  );
}
