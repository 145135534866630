import React, { useState } from "react";
import { Link } from "react-router-dom";

const API_TOKEN = process.env.REACT_APP_API_TOKEN
const LIST_UID = process.env.REACT_APP_LIST_UID


const CallToActionThree = () => {

  //inside the component
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [tags, setTags] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `api_token=${API_TOKEN}&list_uid=${LIST_UID}&EMAIL=${email}&FIRST_NAME=${firstName}&LAST_NAME=${lastName}&tag=${tags}`
    };

    fetch('https://app.emailhup.com/api/v1/subscribers', requestOptions)
    .catch(resp => {
      console.log('There was an error subscribing:', resp);
      alert('Thanks for subscribing. If successful, we would send you a message.');
      setEmail('');
    });
    

  };



  // const handleSubmit = (event) => {
  //   event.preventDefault();
  // };
  return (
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="title-style-one">
          <h6 className="font-rubik" style={{ color: "#8e84e0" }}>
            Start your free trial.
          </h6>
          <h2>New here? Start your free trial now.</h2>
        </div>
        {/* /.title-style-one */}
      </div>
      {/* End .col */}

      <div className="col-lg-6">
        <div className="form-wrapper">
          <form onSubmit={handleSubmit}>
            <input type="email" value={email} placeholder="Email address" onChange={(event) => setEmail(event.target.value)} />
            <button className="bg-dark">Subscribe</button>
          </form>
          {/* End form */}
          <p className="font-rubik">
            Already a member? <Link to="https://app.emailhup.com/">Sign in.</Link>
          </p>
        </div>
        {/* /.form-wrapper */}
      </div>
      {/* End .col */}
    </div>
  );
};

export default CallToActionThree;
