import React from 'react'
import Tilt from "react-parallax-tilt";
// { Link } from "react-router-dom";

const PricingContent = [
  // {
  //   icon: "85",
  //   packName: "Dimond Plan",
  //   features: ["Unlimited Email", "5gb Hosting & Domain", "Email & Live chat."],
  //   price: "59.99",
  //   trialText: "up to 10 user + 1.99 per user",
  //   activeClass: "active",
  // },
  {
    icon: "tb",
    packName: "Others",
    packText: "10,000 Subscribers",
    features: [],
    xfeatures: ["ActiveCampaign", "Mailchimp", "GetResponse", "ConvertKit", "Limited Subscribers", "AWeber"],
    xprice: "200.99",
    trialText: "Why Pay More?",
    activeClass: "active",
  },
  {
    icon: "86",
    icon2: "159",
    packName: "EmailHup",
    packText: "10,000 Subscribers",
    features: [
      "Unlimited Access",
      "Import Bulk Contacts",
      "API Integrations",
      "Powerful Analytics",
      "Drag & Drop Builder",
      "Email Campaigns",
      "Unlimited SMTP Servers",
      "Workflow Automations",
      "Unlimited Segmentation",
      "Lead Capture Popups",
      "Lead Webforms",
      "Open & Click Tracking",
    ],
    xfeatures: [],
    price: "79.99",
    trialText: "One Time payment",
    button: "Get Started",
    activeClass: "most-popular",
  }
  // {
  //   icon: "86",
  //   icon2: "159",
  //   packName: "Dimond Plan",
  //   features: [
  //     "Unlimited Email",
  //     "5gb Hosting & Domain",
  //     "Email & Live chat.",
  //     "3 Domain",
  //   ],
  //   price: "29.99",
  //   trialText: "up to 7 user + 1.99 per user",
  //   activeClass: "most-popular",
  // }
  
];

const PricingFiveCopy = () => {
  return (
    <div className="row align-items-center" >
      {PricingContent.map((val, i) => (
        <div className="col-md-6" key={i}>
          <Tilt>
            <div className={`pr-table-wrapper ${val.activeClass}`}>
              <img
                src={`images/icon/${val.icon}.svg`}
                alt="icon"
                className="icon"
              />
              <img
                src={`images/shape/${val.icon2}.svg`}
                className="popular-badge"
                alt="shape"
              />
              <div className="pack-name">{val.packName}</div>
              <div className="trial-text">{val.packText}</div>
              
              <ul className="pr-feature">
                {val.features.map((list, i) => (
                  <li key={i}>{list}</li>
                ))}
              </ul>
              <ul className="text-white">
                {val.xfeatures.map((list, i) => (
                  <li key={i} className="d-flex inline-block mr-5 p-1"><img className="mx-2" src={`images/icon/c1.svg`} alt="EmailHup"/>{list}</li>
                ))}
              </ul>
              <div className="price text-decoration-line-through">{val.xprice}</div>
              <div className="price ">{val.price}</div>
              <div className="trial-text">{val.trialText}</div>
              <a
                href="https://app.emailhup.com/register"
                className="trial-button theme-btn-fourteen" style={{
                 
                }}
              >
                {val.button}
              </a>
            </div>
            {/* /.pr-table-wrapper */}
          </Tilt>
        </div>
      ))}
    </div>
  );
}

export default PricingFiveCopy