/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

const socialContent = [
  {
    icon: "fa-facebook",
    link: "https://www.facebook.com/",
  },
  {
    icon: "fa-twitter",
    link: "https://twitter.com/",
  },
  {
    icon: "fa-linkedin",
    link: "https://www.linkedin.com/",
  },
  {
    icon: "fa-instagram",
    link: "https://www.instagram.com/",
  },
];

const FooterTwo = () => {
  return (
    <div className="row justify-content-between">
      <div
        className="col-lg-2 col-12 footer-about-widget"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <a href="index-customer-support.html" className="logo">
          <img src="images/logo/elogo.png" alt="brand" />
        </a>
      </div>
      {/* /.about-widget */}
      <div
        className="col-lg-2 col-md-3 col-sm-6 footer-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="50"
      >
        <h5 className="footer-title">Quick Links</h5>
        <ul>
          <li>
          <Link to="https://app.emailhup.com/login">
          Login</Link>
          </li>
          <li>
            <a href="/pricing">Pricing</a>
          </li>
          <li>
            <Link to="/emailhup-features">Features</Link>
          </li>
          <li>
            <Link to="/faq">FAQS</Link>
          </li>
          <li>
            <Link to="/docs">Docs</Link>
          </li>
          {/* <li>
            <Link to="/contact-cs">Support</Link>
          </li> */}
          <li>
            <Link to="/blog">Blog</Link>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
      <div
        className="col-lg-2 col-md-3 col-sm-6 footer-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <h5 className="footer-title">Features</h5>
        <ul>
          <li>
            <Link to="/emailhup-features">Email Marketing</Link>
          </li>
          <li>
            <Link to="/emailhup-features">Marketing Automation</Link>
          </li>
          {/* <li>
            <Link to="/emailhup-features">Contact CRM</Link>
          </li> */}
          <li>
            <Link to="/emailhup-features">Lead Webform</Link>
          </li>
          <li>
            <Link to="/emailhup-features">Popup Builder</Link>
          </li>
          <li>
            <Link to="/emailhup-features">Email Verification</Link>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
      <div
        className="col-lg-2 col-md-3 col-sm-6 footer-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="150"
      >
        <h5 className="footer-title">Legal</h5>
        <ul>
          <li>
            <Link to="/emailhup-features">About us</Link>
          </li>
          <li>
            <Link to="/terms">Terms of Services</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy Policy</Link>
          </li>
          {/* <li>
            <Link to="/pricing-cs">Refund Policy</Link>
          </li> */}
          <li>
            <Link to="/gdpr">GDPR</Link>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
      <div
        className="col-lg-3 col-md-3 col-sm-6 address-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="200"
      >
        <h5 className="footer-title">Support</h5>
        <ul className="info">
          <li>
            <a href="mailto:info@emailhup.com">info@emailhup.com</a>
          </li>
          <li>
            <a href="Tel: +1800-429-7769" className="mobile-num">
              800-429-7769
            </a>
          </li>
        </ul>
        <ul className="social-icon d-flex">
          {socialContent.map((val, i) => (
            <li key={i}>
              <a href={val.link} target="_blank" rel="noreferrer">
                <i className={`fa ${val.icon}`}></i>
              </a>
            </li>
          ))}
        </ul>
      </div>
      {/* /.footer-list */}
    </div>
    //.row
  );
};

export default FooterTwo;
