import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AllRoutes from "./router/AllRoutes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";
import "bootstrap/dist/js/bootstrap";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  return (
    <>
      <Helmet>
        <title>
          EmailHup - Email, Newsletters & Marketing Automation Platform
        </title>
        <meta property="og:site_name" content="deski" />
        <meta property="og:url" content="https://emailhup.com" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="EmailHup: Email, Newsletters & Marketing Automation Platform"
        />
        <meta
          name="keywords"
          content="Email marketing, email campaigns, email automation, email list management, email templates, email design, email reporting, email deliverability, email list growth, email marketing software"
        />
        <meta
          name="description"
          content="EmailHup is the comprehensive email marketing platform that enables businesses of all sizes to automate, manage, and analyze effective email campaigns."
        />
        <meta
          name="description"
          content="Email, Newsletters & Marketing Automation Platform"
        />
      </Helmet>

      {/* End Seo Helmt */}

      <ScrollToTop />
      {/* End Scroll top */}

      <AllRoutes />
    </>
  );
};

export default App;
