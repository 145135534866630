import React from "react";

const CopyRightTwo = () => {
  return (
    <div className="bottom-footer-content">
      <div className="d-flex align-items-center justify-content-center">
        <p>
          {" "}
          Copyright @{new Date().getFullYear()}{" "}
          <a
            href="https://emailhup.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            EmailHup.
          </a>{" "}
          Powered by {" "}  
          <a
            href="https://oanwave.com"
            target="_blank"
            rel="noopener noreferrer"
          >Oanwave</a>Technology Inc
        </p>
      </div>
    </div>
    //   /.bottom-footer
  );
};

export default CopyRightTwo;
