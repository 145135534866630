export const buttonHoverStyle = {
    backgroundPosition: "right center",
    color: "#fff",
    textDecoration: "none"
  };


export const buttonStyleSmall = {
    backgroundImage: "linear-gradient(to right, #33E6B2 0%, #8e84e0 51%, #F6F2E8 100%)",
    margin: "5px",
    padding: "4px 25px",
    textAlign: "center",
    
    transition: "0.5s",
    backgroundSize: "200% auto",
    color: "#4a4661",            
    boxShadow: "0 0 20px #eee",
    borderRadius: "10px",
    display: "block"
  };
  
export const glass = {
  backgroundColor: 'rgba(255, 255, 255, 0.15)',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8px)',
  WebkitBackdropFilter: 'blur(8px)',
  borderRadius: '10px',
  };