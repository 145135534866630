/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Helmet } from "react-helmet";
import FancyFeatureTen from "../../components/features/FancyFeatureTen";
import HeaderLanding from "../../components/header/landing/HeaderLanding";
import HeroBannerFive from "../../components/hero-banner/HeroBannerFive";
// import FancyTextBlock21 from "../../components/fancy-text-block/FancyTextBlock21";
// import CounterTwo from "../../components/counter/CounterTwo";
 import FancyFeatureEleven from "../../components/features/FancyFeatureEleven";
 //import Pricing from "../../components/pricing/pricing-four/Pricing";
import TestimonialFour from "../../components/testimonial/TestimonialFour";
//import FooterFive from "../../components/footer/FooterFive";

import FancyFeatureSeventeen from "../../components/features/FancyFeatureSeventeen";
//import FancyTextBlock29 from "../../components/fancy-text-block/FancyTextBlock29";
// import PricingFive from "../../components/pricing/PricingFive";
import PricingFiveCopy from "../../components/pricing/PricingFiveCopy";
//import Tilt from "react-parallax-tilt";
import FooterTwo from "../../components/footer/FooterTwo";
import CopyRightTwo from "../../components/footer/CopyRightTwo";
//import DocSlickAsNavTwo from "../../components/block-slider/DocSlickAsNavTwo";
//import { Link } from "react-router-dom";
//import BlockMetaData from "../../components/fancy-text-block/BlockMetaData";
// BrandTwo from "../../components/brand/BrandTwo";
import DocSlickAsNav from "../../components/block-slider/DocSlickAsNav";
//import HeroBannerThirteen from "../../components/hero-banner/HeroBannerThirteen";
//import HeaderLandingWebsiteBuilder from "../../components/header/landing/HeaderLandingWebsiteBuilder";
import BlockMetaData from "../../components/fancy-text-block/BlockMetaData";
import BlockMetaDataTwo from "../../components/fancy-text-block/BlockMetaDataTwo";
//import CheckIcon from '@mui/icons-material/Check';
import Typewriter from "typewriter-effect";
import { Link } from "react-router-dom";

const bodyStyle = {
  // background: 'linear-gradient(0deg, #724fe5 6.25%, #32e7b1 31.77%, rgba(255, 255, 255, 0) 75.21%)',
  background:
    "linear-gradient(0deg, rgba(142,132,224,1) 14%, rgba(51,230,178,1) 35%, rgba(15,17,35,1) 71%)",
};

const ProductLanding = () => {
  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title>
          {" "}
          EmailHup: The Ultimate Email Marketing Platform for Effective
          Campaigns and Growth
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLanding />
      {/* <HeaderLandingWebsiteBuilder /> */}
      {/* End HeaderLanding */}

      {/* <div id="home">
        <HeroBannerThirteen />
      </div> */}

      <div id="home">
        <HeroBannerFive />
      </div>
      {/* End HeaderBannerFive */}

      {/* ======================================= */}

      {/* 	=============================================
				Fancy Feature Ten
			==============================================  */}
      <div className="fancy-feature-ten pt-100 md-pt-70" id="features">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div
                className="col-md-6"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="title-style-six">
                  <h2>
                    The <span style={{color:"#714FE4"}}>Ultimate</span> Email Marketing Platform
                  </h2>
                </div>
                {/* /.title-style-six */}
              </div>
              {/* End .col */}

              <div
                className="col-lg-5 col-md-6"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <p className="sub-text pt-30 pb-30">
                  With Emailhup, you can create, send, and track powerful email
                  campaigns that drive engagement and sales.
                </p>
              </div>
              {/* End .col */}
            </div>
            <FancyFeatureTen />
            {/* End Fancy Feature Ten */}

            {/* =============================================
				Fancy Block Style One
		    ============================================== */}
            <div className="block-style-one mt-5 p">
              <div className="container">
                <div className="d-lg-flex align-items-center justify-content-between inner-container">
                  <BlockMetaData />
                </div>
              </div>
              {/* /.container */}
            </div>
            {/* /.block-style-one */}
          </div>

          <img
            src="images/shape/137.svg"
            alt="shape"
            className="shapes shape-one"
          />
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-feature-ten */}

      {/* =============================================== */}

      {/* =====================================================
            Fancy Feature Seventeen
        ===================================================== */}
      <div
        className="fancy-feature-seventeen lg-container pt-150 md-pt-90"
        id="feature"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="title-style-six text-center mb-130 md-mb-90">
                <div className="upper-title">Features</div>
                <h2>
                  {" "}
                  We’ve ton of features for your{" "}
                  <span style={{color:"#714FE4"}}>
                    email marketing
                    {/* <img
                      src="images/shape/line-shape-14.svg"
                      alt="line shape"
                    /> */}
                  </span>
                </h2>
              </div>
            </div>
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}

        <div className="container">
          <div className="bottom-border pb-50">
            <div className="row">
              <FancyFeatureSeventeen />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.fancy-feature-seventeen --> */}

      {/*=============================================
				Document Slide As Nav
		============================================== */}
      <div className="fancy-text-block-sixteen mt-200 md-mt-80">
        <div className="container">
          <DocSlickAsNav />
        </div>
      </div>
      {/* End text block based image slide */}





      {/*=============================================
			Fancy Feature Thirteen
      style={{
  background: 'linear-gradient(0deg, rgba(142,132,224,1) 14%, rgba(51,230,178,1) 35%, rgba(15,17,35,1) 71%)',}}
		  ==============================================  */}
      {/* <div
        className="fancy-feature-thirteen pt-100 pb-100 md-pt-110"
        id="features"
        style={{
          // background:
          //   "linear-gradient(0deg, rgba(142,132,224,1) 14%, rgba(51,230,178,1) 35%, rgba(15,17,35,1) 71%)",
          
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-11 col-md-10 m-auto">
              <div className="title-style-six text-center ">
                <h2 className="text-light">
                  Unlock the Full Potential of
                  <Typewriter
                    options={{
                      strings: [
                        "Email Marketing",
                        "Email Newsletter",
                        "Unlimited Subscribers",
                        "Email Automation",
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />{" "}
                  with EmailHup
                </h2>
                <p className="text-xs">
                  Get access to advanced automation tools and take your email
                  campaigns to the next level. Sign up now!"
                </p>
              </div>
              
            </div>
          </div>

          <div className="sldier-wrapper  mt-30 md-mt-50">
            <DocSlickAsNavTwo />
          </div>
          
        </div>
      </div> */}
      {/* /.fancy-feature-thirteen */}




      {/* =============================================
            Fancy Feature Twenty Nine
        ==============================================  */}
      {/* <div
        className="fancy-feature-twentyNine lg-container pt-150 mt-20 md-pt-70"
        id="p-tour"
      >
        <FancyTextBlock29 />
        
      </div>   */}

      {/* =============================================
				Fancy Block Style Two
		    ============================================== */}
      <div className="block-style-one mt-5 p">
        <div className="container">
          <div className="d-lg-flex align-items-center justify-content-between inner-container">
            <BlockMetaDataTwo />
          </div>
        </div>
        {/* /.container */}
      </div>
      {/* /.block-style-one */}

      {/* 	=====================================================
				Pricing Section Five
			===================================================== */}
      <div
        className="pricing-section-five pt-250 md-mt-150 sm-mt-80 md-pt-80 pb-100"
        style={{background: '#0E1122'}}
        id="pricing"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 order-lg-last">
              <div className="title-style-six ps-5 md-p0">
                <h6 style={{ fontSize: "15px" }}>Our Pricing</h6>
                <h2 className="text-white">
                  Unlimited Access <span style={{color:"#33E6B2"}}>Today</span>
                </h2>
                <p className="text-xs fw-bold">
                  Unlimted access to use Emailhup for the next 5000 users
                </p>
              </div>
              {/* /.title-style-six */}
            </div>
            {/* End .col */}

            <div className="col-lg-7">
              <div className="pricing-table-area-five md-mt-60">
                <img
                  src="images/icon/88.png"
                  alt="icon"
                  className="shapes shape-one"
                />
                <img
                  src="images/shape/160.png"
                  alt="shape"
                  className="shapes shape-two"
                />
                {/* <PricingFive/> */}
                <PricingFiveCopy />
              </div>
              {/* /.pricing-table-area-five */}
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </div>
      </div>
      {/* /.pricing-section-five */}

      {/*   =============================================
				Fancy Text block Twenty One
			==============================================  */}
      {/* <div className="fancy-text-block-twentyOne pt-170 md-pt-100" id="about">
        <div className="container">
          <FancyTextBlock21 />
        </div>
      </div> */}
      {/* /.fancy-text-block-twentyOne */}

      {/*  =====================================================
				Counter Style Two
			===================================================== */}
      {/* <div className="counter-style-two mt-150 md-mt-60">
        <div className="border-bottom">
          <div className="container">
            <CounterTwo />
          </div>
        </div>
      </div> */}
      {/* /.counter-style-two */}

      {/*   =====================================================
				Fancy Feature Eleven
			===================================================== */}
      <div className="fancy-feature-eleven pt-130 md-pt-80" id="product">
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-10 col-md-9 m-auto">
                <div className="title-style-six text-center">
                  <h6>Our Features</h6>
                  <h2>
                    We’re helping <span style={{color:"#714FE4"}}>customer</span> globally now.
                  </h2>
                  <div data-aos="fade-up" data-aos-delay="200">
                  <a
                    href="https://app.emailhup.com/users/register"
                    className="theme-btn-fourteen"
                  >
                    Get Started for free{" "}
                    <i
                      className="fa fa-angle-right ms-1"
                      aria-hidden="true"
                    ></i>
                  </a>
                </div>
                </div>
      {/* /.title-style-six */}
      </div>
            </div>
          </div>
      {/* End .container */}
      <FancyFeatureEleven />
        </div>
      {/* /.inner-container */}
         <img
           src="images/shape/139.svg"
          alt="shape"
           className="shapes shape-one"
         />
         <img
           src="images/shape/140.svg"
           alt="shape"
           className="shapes shape-two"
         />
       </div>
      {/* /.fancy-feature-eleven */}

      {/* =====================================================
				Pricing Section Four style={bodyStyle}
			=====================================================  */}
      {/* <div className="pricing-section-four pt-200 md-pt-100 pb-100" id="pricing">
        <div className="container">
          <div className="row">
            <div className="col-xl-10  m-auto">
              <div className="title-style-six text-center">
                <h2>
                  Solo, Agency or Team? We’ve got you <span>covered.</span>
                </h2>
              </div>
              
            </div>
          </div>
        </div>
       

        <Pricing />
      </div> */}
      {/*  /.pricing-section-four */}

      {/*  =====================================================
				Client Feedback Slider Four
			===================================================== */}
      <div
        className="client-feedback-slider-four mt-200 md-mt-100"
        id="feedback"
      >
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-9 col-md-8 m-auto">
                <div className="title-style-six text-center">
                  <h2>
                    <span style={{color:"#714FE4"}}>13,000+</span> Clients love Our Platform
                  </h2>
                </div>
                {/* /.title-style-six */}
              </div>
            </div>
          </div>
          <div className="clientSliderFour slick-nav-none">
            <TestimonialFour />
          </div>
          <img
            src="images/shape/141.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/142.svg"
            alt="shape"
            className="shapes shape-two"
          />
        </div>
        {/* /.inner-container */}
      </div>
      {/* /.client-feedback-slider-four */}

      {/*  =====================================================
				Fancy Short Banner Six
			===================================================== */}
      <div className="fancy-short-banner-six mt-150 md-mt-80">
        <img
          src="images/shape/143.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <div className="container">
          <div className="row">
            <div
              className="col-xl-9 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-six text-center">
                <h2>
                  Ready to Supercharge Your Email Marketing? <br />
                  {/* <span>Save 50%</span> by grab it today. */}
                </h2>
              </div>
              
            </div>
          </div>
          <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
            Try it risk free — we don’t charge cancellation fees.
          </p>
          <a
            href="#pricing"
            className="theme-btn-fourteen"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            Get Unlimited Access
          </a>
        </div>
       
      </div>
      {/* /.fancy-short-banner-six */}

      {/* =====================================================
				Footer Style Five
			===================================================== */}
      {/* <footer className="theme-footer-five mt-130 md-mt-100">
        <div className="inner-container">
          <div className="container">
            <FooterFive />
          </div>
        </div>
       // {/* /.inner-container *
        <p className="copyright">
          {" "}
          Copyright @{new Date().getFullYear()}{" "}
          <a
            href="https://themeforest.net/user/ib-themes/portfolio"
            target="_blank"
            rel="noreferrer"
          >
            ib-themes
          </a>{" "}
          inc.
        </p>
      </footer> */}
      {/*  /.theme-footer-five */}

      {/* 	=====================================================
				Footer Style Two
			===================================================== */}
      <footer className="theme-footer-two pt-150 md-pt-80">
        <div className="top-footer">
          <div className="container">
            <FooterTwo />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightTwo />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default ProductLanding;
