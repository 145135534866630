import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import HeaderLanding from "../../components/header/landing/HeaderLanding";
import Social from "../../components/social/Social";
import FooterTwo from "../../components/footer/FooterTwo";
import CopyRightTwo from "../../components/footer/CopyRightTwo";
//import AboutFour from "../../components/about/AboutFour";
import CallToActionThree from "../../components/call-to-action/CallToActionThree";
import BrandTwo from "../../components/brand/BrandTwo";
import CheckIcon from "@mui/icons-material/Check";
import FancyVideoFour from "../../components/video/FancyVideoFour";

// import Lottie from "lottie-react";
// import emailhuplottie from "../../components/emailhuplottie";
import Typewriter from 'typewriter-effect';

const featureData = [
  {
    id: 1,
    name: "Sell Your Products",
    description:
      "With EmailHup, you can create email campaigns that not only promote your products or services, but also allow your subscribers to purchase them directly from the email.",
    image: "111.png",
  },
  {
    id: 2,
    name: "Mobile-Friendly Design",
    description:
      "EmailHup's templates and designs are optimized for mobile devices, ensuring your emails look great and function properly on any device.",
    image: "112.png",
  },
  {
    id: 3,
    name: "Customizable Sign-up Forms and Popups",
    description:
      "Grow your email list with customizable sign-up forms and popups that can be easily integrated into your website.",
    image: "116.webp",
  },
  {
    id: 4,
    name: "Amazon SES Integration",
    description:
      "Reach your audience with confidence knowing your messages are being sent with the reliability and security of Amazon SES. Send up to 10,000 emails for just $1.",
    image: "113.webp",
  },
  {
    id: 5,
    name: "Connect to Multiple SMTP Servers",
    description:
      "Connect to an unlimited number of SMTP servers to ensure your emails always reach your customers' inboxes and make a lasting impression on your audience.",
    image: "117.webp",
  },
  {
    id: 6,
    name: "Email Validation",
    description:
      "Integrate your favorite email list validation tool to ensure your email list is clean, up-to-date, and deliverable.",
    image: "114.webp",
  },
  {
    id: 7,
    name: "Drag and Drop Email Builder",
    description:
      "Easily create customizable email templates or design from scratch using our intuitive drag-and-drop email builder.",
    image: "115.webp",
  },
  {
    id: 8,
    name: "Marketing Automation",
    description:
      "Automate your email marketing by setting up a series of emails to automatically follow up with your subscribers based on their actions, and send one-off emails at specific dates or annually.",
    image: "119.webp",
  },
];

const EmailhupFeatures = () => {
  console.log(featureData);
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          EmailHup Features || Unlock the Full Potential of Email Marketing with
          EmailHup
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLanding />
      {/* End Header */}

      {/*=============================================
			Hero Banner One
		============================================== */}
      <div
        className="hero-banner-five lg-container mt-lg-5"
        style={{
          background:
            "linear-gradient(0deg, #724fe5 6.25%, #32e7b1 31.77%, rgba(255, 255, 255, 0) 85.21%)",
          borderRadius: "0 0 50% 50%",
          width: "150vw",
          left: "-25vw",
        }}
      >
        <div className="container">
          <div className="hero-banner-one">
            <div className="hero-upper-container">
              <div className="icon-box-one">
                <img src="images/logo/elogo.png" alt="logo" />
              </div>
              <div className="icon-box-two">
                <img src="images/logo/1a.png" alt="logo" />
              </div>
              <div className="icon-box-three">
                <img src="images/logo/2a.png" alt="logo" />
              </div>
              <div className="icon-box-four">
                <img src="images/logo/3a.png" alt="logo" />
              </div>
              <div className="icon-box-five">
                <img src="images/logo/4a.png" alt="logo" />
              </div>
              <div className="icon-box-six">
                <img src="images/logo/5a.png" alt="logo" />
              </div>
              <div className="icon-box-seven">
                <img src="images/logo/6a.png" alt="logo" />
              </div>
              <div className="icon-box-eight">
                <img src="images/logo/7a.png" alt="logo" />
              </div>

              <div className="bubble-one"></div>
              <div className="bubble-two"></div>
              <div className="bubble-three"></div>
              <div className="bubble-four"></div>
              <div className="bubble-five"></div>
              <div className="bubble-six"></div>
              {/* End all bubble images */}

              <div className="container">
                <div className="row">
                  <div className="col-xl-8 col-lg-10 m-auto">
                    <h2 className="font-recoleta hero-heading text-dark">
                      Advanced Features for Maximum <span><Typewriter
  options={{
    strings: [
      "Engagement",
      "Efficiency",
      "Success",
  ],
    autoStart: true,
    loop: true,
  }}
/></span>
                    </h2>
                    <p className="hero-sub-heading text-mute fs-5">
                      Take your email campaigns to the next level with
                      EmailHup's powerful suite of automation tools
                    </p>
                  </div>
                  {/* End .col */}
                </div>
                {/* End row */}

                {/* <form
              action="#"
              className="subscription-form"
              // onClick={handleSubmit}
            >
              <input type="email" placeholder="Your email" />
              <button>Try for free</button>
            </form> */}
                {/* End form */}
                <div data-aos="fade-up" data-aos-delay="200">
                  <Link
                    to="https://app.emailhup.com/users/register"
                    className="theme-btn-fourteen"
                  >
                    Get Started for free{" "}
                    <i
                      className="fa fa-angle-right ms-1"
                      aria-hidden="true"
                    ></i>
                  </Link>
                </div>
                <p className="text-dark" style={{ fontSize: "12px" }}>
                  <CheckIcon style={{ color: "#8e84e0" }} /> 30-Day Money-Back
                  Guarantee <CheckIcon style={{ color: "#8e84e0" }} />
                  Join 6,000+ satisfied customers{" "}
                  <CheckIcon style={{ color: "#8e84e0" }} />
                  Lifetime Updates
                </p>

                <p className="sing-in-call">
                  Already using Emailhup?{" "}
                  <Link to="https://app.emailhup.com/">Sign in.</Link>
                </p>
              </div>
              {/* End .container */}
            </div>
            {/* /.hero-upper-container */}
          </div>
          {/* /.hero-banner-one */}


          {/* =============================================
            Video Box Two
        ==============================================  */}
      <div className="video-box-two lg-container">
        <div className="container">
          <FancyVideoFour />
        </div>
      </div>
      {/* /.video-box-two */}

      

          {/* <div
            className="img-gallery"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="400"
          >
            <div className="container text-center">
              <div className="screen-container">
                <img
              src="images/assets/screen3.png"
              alt="shape"
              className="main-screen"
              
            />
                <div
                  className="mt-0"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div style={{ maxWidth: "100%", width: "1400px" }}>
                    <Lottie animationData={emailhuplottie} loop={true} />
                  </div>
                </div>
                <img
                  src="images/assets/screen_01.1.png"
                  alt="shape"
                  className="shapes screen-one"
                />
                <img
                  src="images/assets/screen_01.2.png"
                  alt="shape"
                  className="shapes screen-two"
                />
              </div>
              
            </div>
          </div> */}
          {/* /.img-gallery */}

          <img
            src="images/shape/133.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/134.svg"
            alt="shape"
            className="shapes shape-two"
          />
          <img
            src="images/shape/135.svg"
            alt="shape"
            className="shapes shape-three"
          />
          <img
            src="images/shape/136.svg"
            alt="shape"
            className="shapes shape-four"
          />

          <div className="partner-slider-two mt-110 md-mt-80">
            <div className="container">
              <p className="text-center">
                Over <span>3k+</span> businesses using EmailHup.
              </p>
              <div className="partnerSliderTwo">
                <BrandTwo />
              </div>
              {/* End .partner slider two */}
            </div>
          </div>
          {/* /.partner-slider-two */}
        </div>
      </div>

      {/* 	=============================================
                    Fancy Hero Three
                ============================================== */}
      <div className="fancy-hero-three bg-transparent">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h2 className="heading font-recoleta ">Check our features in details</h2>
                <p className="sub-heading">
                  EmailHup helps you send personalized emails, set automation
                  workflows, add multiple SMTPs, and much more.
                </p>
                <Link
                  to="/pricing"
                  // className="theme-btn-two trial-button mt-50 md-mt-30 bg-primary"
                  className="theme-btn-fourteen trial-button mt-50 md-mt-30"
                >
                  {" "}
                  Get Deal Now
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      {/* /.fancy-hero-three */}

      {/* 	=============================================
                    Fancy Feature Seven
                ==============================================  */}
      <div className="fancy-feature-seven">
        <div className="container">
          <div className="title-style-six text-center mb-150 md-mb-70">
            <p>EmailHup</p>
            <h2>
              All-In-One Email Marketing Toolkit- Explore Our Key Features
              {/* <span>
                Explore Our{" "} */}
                {/* <img src="images/shape/line-shape-8.svg" alt="line shape" /> */}
              {/* </span>{" "}
              Key Features */}
            </h2>
          </div>
          {/* /.title-style-two */}

          <div className="block-wrapper">
            {featureData.map((f) => (
              <div className="block-style-nine">
                <div className="row align-items-center">
                  <div
                    className="col-lg-7 col-md-9 m-auto"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="illustration-holder">
                      <img src={`images/assets/${f.image}`} alt="illustrator" />
                    </div>
                    {/* /.illustration-holder */}
                  </div>
                  <div
                    className="col-lg-5"
                    data-aos="fade-left"
                    data-aos-duration="1200"
                  >
                    <div className="text-wrapper">
                      <h6>{f.name}</h6>
                      <h3 className="title font-recoleta">
                        EmailHup Provides: {f.name}
                      </h3>
                      <p className="font-rubik">{f.description}</p>
                    </div>
                    {/* /.text-wrapper */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* /.fancy-feature-seven */}

      {/* =====================================================
                    Fancy Text Block Thirteen
                ===================================================== */}
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-six">
                  <h2>Security you can trust easily.</h2>
                </div>
                {/* /.title-style-two */}
                <p>
                  Our dashboard is served over SSL which means you can be sure
                  that all data transferred is secure. Payment processing uses
                  the very latest PCI DSS card-security standards
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <img src="images/assets/118.webp" alt="feature" />
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
      {/* /.fancy-text-block-thirteen */}

      {/*=====================================================
                    Useable Tools
                ===================================================== */}
      <div className="useable-tools-section-two bg-shape mb-250 mt-150 md-mt-100 md-mb-100">
        <div className="bg-wrapper">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <div className="container">
            <div className="title-style-six text-center mb-70 md-mb-10">
              <div className="row">
                <div className="col-lg-10 col-md-11 m-auto">
                  <p>Integrates with your external emailing tools</p>
                  <h2>
                    Connect EmailHup with the software you use every day
                    {/* <span>
                      use every
                      <img src="images/shape/line-shape-2.svg" alt="" />
                    </span>
                    day. */}
                  </h2>
                </div>
              </div>
            </div>
            {/*  /.title-style-two */}

            <div className="icon-wrapper">
              <Social />
            </div>
            {/* /.icon-wrapper */}
          </div>
          {/* /.container */}
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.useable-tools-section-two */}

      {/* 	=====================================================
                    Fancy Text Block Fourteen
                ===================================================== */}
      {/* <div className="fancy-text-block-fourteen pt-30">
        <div className="container">
          <AboutFour />
        </div>
      </div> */}
      {/* /.fancy-text-block-fourteen */}

      {/* 	=====================================================
                Fancy Short Banner Three
                ===================================================== */}
      <div className="fancy-short-banner-three mt-225 md-mt-150">
        <div className="container">
          <div className="bg-wrapper">
            <CallToActionThree />
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-short-banner-four */}

      {/* 	=====================================================
                    Footer Style Two
                ===================================================== */}
      <footer className="theme-footer-two pt-150 md-pt-80">
        <div className="top-footer">
          <div className="container">
            <FooterTwo />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightTwo />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default EmailhupFeatures;
