import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialFour() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      img: "77",
      desc: `I've been blown away by the power of Emailhup's email marketing platform. The ability to create targeted campaigns and hyper-personalize my messages with liquid tags has made a huge difference for my business. I highly recommend this platform!`,
      reviewerName: "Marcus T",
      country: "USA",
      stars: ["fa-star", "fa-star", "fa-star", "fa-star", "fa-star-o"],
    },
    {
      img: "77",
      desc: `Emailhup's drag and drop email builder is a game-changer for me. As a non-technical user, I appreciate the simplicity and ease of use, yet I'm still able to create beautiful and effective emails that drive results. Thanks, Emailhup!`,
      reviewerName: "Bostami Hasan",
      country: "USA",
      stars: ["fa-star", "fa-star", "fa-star", "fa-star", "fa-star-o"],
    },
    {
      img: "77",
      desc: `I've tried a lot of email marketing platforms in the past, but Emailhup really stands out. The rich text and HTML code editor lets me customize my emails to my heart's content, and the ability to curate my own template libraries has saved me so much time. This is a must-have platform for any serious email marketer.`,
      reviewerName: "Kuyeti Krima",
      country: "USA",
      stars: ["fa-star", "fa-star", "fa-star", "fa-star", "fa-star-o"],
    },
    {
      img: "77",
      desc: `Emailhup has revolutionized the way I do email marketing. The contact segmentation feature has helped me create highly targeted campaigns that really resonate with my audience. And the email list cleaning feature has kept my list healthy and effective. Thanks for making my job so much easier!`,
      reviewerName: "Zibaiya Reian",
      country: "USA",
      stars: ["fa-star", "fa-star", "fa-star", "fa-star", "fa-star-o"],
    },
    {
      img: "77",
      desc: `I'm a big fan of Emailhup's email marketing platform. The ability to import HTML email templates and transform them with fresh new looks in seconds is a huge time-saver for me. And the pixel-perfect email design lets me create emails that look great on any device. Keep up the great work!`,
      reviewerName: "Riana Sariya",
      country: "USA",
      stars: ["fa-star", "fa-star", "fa-star", "fa-star", "fa-star-o"],
    },
  ];

  return (
    <Slider {...settings}>
      {TestimonilContent.map((val, i) => (
        <div className="item" key={i}>
          <div className="feedback-wrapper">
            <img
              src={`images/icon/${val.img}.svg`}
              alt="icon"
              className="icon"
            />
            <p>{val.desc}</p>
            <div className="d-sm-flex justify-content-between align-items-center">
              <h6 className="name">
                {val.reviewerName},<span>{val.country}</span>
              </h6>
              <ul className="d-flex">
                {val.stars.map((star, i) => (
                  <li key={i}>
                    <i className={`fa ${star}`} aria-hidden="true"></i>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
}
