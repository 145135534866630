import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
  "title": "Why is EmailHup so cost-effective?",
  "desc": "EmailHup offers affordable pricing by leveraging SMTP integrations with popular service providers like Amazon SES, allowing you to send emails at a fraction of the cost compared to other email services.",
  "expand": "a"
  },
  {
  "title": "What is Email Automation?",
  "desc": "Email Automation enables you to automate various marketing tasks, from capturing leads to onboarding customers and beyond. It includes features such as welcome messages, upselling campaigns, re-engagement campaigns, and feedback requests.",
  "expand": "b"
  },
  {
  "title": "How does EmailHup handle email bounces and spam?",
  "desc": "EmailHup tracks bounces and spam complaints, blacklists invalid contacts, and provides analysis on your email campaigns to improve deliverability and spam scores.",
  "expand": "c"
  },
  {
  "title": "Can I cancel a one-time payment deal?",
  "desc": "To cancel a one-time payment deal, simply contact our support team. If canceled within 30 days of payment, we offer a 100% money-back guarantee, no questions asked.",
  "expand": "d"
  },
  {
  "title": "How can I add contacts to my EmailHup list?",
  "desc": "You can import contacts in bulk using CSV, utilize APIs, or integrate embedded HTML forms and popups to add contacts to your EmailHup list.",
  "expand": "e"
  },
  {
  "title": "Are there recurring fees for EmailHup?",
  "desc": "Currently, EmailHup offers full access without any recurring fees for a limited time.",
  "expand": "f"
  },
  {
  "title": "Why is there a significant price difference compared to other solutions?",
  "desc": "The price difference is due to our exclusive one-time payment offer. Once the offer expires, the pricing will align with similar feature-rich solutions in the market.",
  "expand": "g"
  }
  ];

const FaqThree = () => {
  return (
    <div className="accordion-style-three">
      <div className="faq-wrraper">
        <Accordion allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem
              className="card"
              key={i}
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqThree;
