import React from "react";
import { glass } from '.././Styles';

const FeaturesContent = [
  {
    img: "amazon.png",
    title: "Amazon SES 1$/10,000",
    desc: ` Reach your audience with confidence knowing your messages are being sent with the reliability and security of one of the world's largest online retailers Amazon $1 / 10,000 Emails`,
  },
  {
    img: "smtp.png",
    title: "Connest SMTP Server",
    desc: ` Connect to an unlimited number of SMTP servers to ensure your emails always reach your customers' inboxes and make a lasting impression on your audience.`,
  },
  {
    img: "m.png",
    title: "Email Validation",
    desc: ` Integrate your favorite email list validation tool. You can now rest well easy, knowing your email list is clean, up-to-date, and deliverable.`,
  },
  {
    img: "94.svg",
    title: "Drag and Drop",
    desc: ` Customizable templates or designing from scratch using our intuitive drag-and-drop email builder - our platform simplifies the process.`,
  },
  {
    img: "95.svg",
    title: "Detail Analytics",
    desc: ` Maximize Your Email Campaigns with Advanced Analytics: Dive Deep into Results with SMTP.`,
  },
  {
    img: "96.svg",
    title: "Marketing Automation",
    desc: ` Automate your marketing by setting up a series of emails to automatically follow up with your subscribers. Identify the user's action with custom tags.`,
  },
  {
    img: "97.svg",
    title: "App Integrations",
    desc: ` Seamlessly view, create, or delete subscribers/lists while keeping track of campaign performance using our state-of-the-art API endpoints.`,
  },
  {
    img: "98.svg",
    title: "HTML/Plain Text",
    desc: ` You can create a plain text email or add HTML code to the email template, making it easy to customize your email marketing campaigns.`,
  },
  {
    img: "99.svg",
    title: "Custom Fields",
    desc: `  Unleash the true potential of your email campaigns by creating custom fields that go beyond the standard 'name' and 'email' fields.`,
  },
];
const FancyFeatureSeventeen = () => {
  return (
    <>
      {FeaturesContent.map((val, i) => (
        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          key={i}
        >
          <div className="block-meta">
            <div className="icon d-flex align-items-end">
              <img src={`images/icon/${val.img}`} alt="icon" />
            </div>
            <h4>{val.title}</h4>
            <p>{val.desc}</p>
          </div>
        </div>
        // .block-meta
      ))}
    </>
  );
};

export default FancyFeatureSeventeen;
